export interface SocketResponse<T = Record<string, any>> {
  data: T;
  action: string;
}

export enum SocketEvent {
  USER = 'user-action',
  TABLE = 'table-action',
  COMMON = 'common-action',
  ACCOUNT = 'account-action',
  TRANSACTION = 'transaction-action',
}
